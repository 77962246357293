import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Flex,
  Button,
  RadioGroup,
  Radio,
  Stack,
  Input,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
} from "@chakra-ui/react";

const OptOut = () => {
  const [value, setValue] = React.useState(1);
  const [sent, setSent] = React.useState(false);
  const [email, SetEmail] = React.useState("");
  const [alert, setAlert] = React.useState(false);

  const handleChange = (event) => SetEmail(event.target.value);

  const submitAddress = async () => {
    if (sent === false) {
      axios
        .put(
          "http://3.95.200.95:3001/OptOut",
          {
            email: email,
            opt_in: value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      setSent(true);
    }
    setAlert(true);
  };

  return !alert ? (
    <Flex
      flexDirection="Column"
      align="flex-start"
      justifyContent="start"
      ml="2%"
    >
      <Text as="b" mt="10">
        Adjust Your Email Frequency
      </Text>

      <Input
        size="md"
        mt="30px"
        minW="50%"
        w="300px"
        variant="outline"
        placeholder="Email"
        value={email}
        onChange={handleChange}
      />

      <RadioGroup onChange={setValue} value={value}>
        <Stack mt="30px" spacing={8}>
          <Radio value={"1"}>Stay Subscribed</Radio>
          <Radio value={"0"}>Unsubscribe</Radio>
        </Stack>
      </RadioGroup>

      <Button mt="30px" colorScheme="blackAlpha" onClick={submitAddress}>
        Submit
      </Button>
    </Flex>
  ) : (
    <div style={{ justifyContent: "center" }}>
      <Alert status="success" style={{ width: "50%" }}>
        <AlertIcon />
        <Box>
          <AlertTitle>Success!</AlertTitle>
          <AlertDescription>
            Your application has been received. We will review your application
            and respond within the next 48 hours.
          </AlertDescription>
        </Box>
        <Link to="/">
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={console.log("closing")}
          />
        </Link>
      </Alert>
    </div>
  );
};
export default OptOut;
