import React, { useState, useEffect } from 'react';
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import {
  Flex,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverBody,
  Button,
  Text
} from "@chakra-ui/react";

const conferences = [
  { value: "acc", label: "ACC" },
  { value: "big10", label: "Big Ten" },
  { value: "big12", label: "Big 12" },
  { value: "sec", label: "SEC" },
  { value: "ind", label: "Independent" },
  { value: "pac12", label: "Pac 4" },
  { value: "aac", label: "AAC" },
  { value: "mac", label: "MAC" },
  { value: "mwc", label: "MWC" },
  { value: "sbc", label: "Sun Belt" },
  { value: "fcs", label: "FCS" },
];

const teams = [
  {
      label: "Air Force",
      value: "AFA"
  },
  {
      label: "Akron",
      value: "AKR"
  },
  {
      label: "Alabama",
      value: "ALA"
  },
  {
      label: "Appalachian State",
      value: "APP"
  },
  {
      label: "Arizona",
      value: "ARIZ"
  },
  {
      label: "Arizona State",
      value: "ASU"
  },
  {
      label: "Arkansas",
      value: "ARK"
  },
  {
      label: "Arkansas State",
      value: "ARST"
  },
  {
      label: "Army",
      value: "ARMY"
  },
  {
      label: "Auburn",
      value: "AUB"
  },
  {
      label: "Ball State",
      value: "BALL"
  },
  {
      label: "Baylor",
      value: "BAY"
  },
  {
      label: "Boise State",
      value: "BSU"
  },
  {
      label: "Boston College",
      value: "BC"
  },
  {
      label: "Bowling Green",
      value: "BGSU"
  },
  {
      label: "Buffalo",
      value: "BUFF"
  },
  {
      label: "BYU",
      value: "BYU"
  },
  {
      label: "California",
      value: "CAL"
  },
  {
      label: "Central Michigan",
      value: "CMU"
  },
  {
      label: "Charlotte",
      value: "CHAR"
  },
  {
      label: "Cincinnati",
      value: "CIN"
  },
  {
      label: "Clemson",
      value: "CLEM"
  },
  {
      label: "Coastal Carolina",
      value: "CCU"
  },
  {
      label: "Colorado",
      value: "COLO"
  },
  {
      label: "Colorado State",
      value: "CSU"
  },
  {
      label: "Connecticut",
      value: "CONN"
  },
  {
      label: "Duke",
      value: "DUKE"
  },
  {
      label: "East Carolina",
      value: "ECU"
  },
  {
      label: "Eastern Michigan",
      value: "EMU"
  },
  {
      label: "Florida",
      value: "FLA"
  },
  {
      label: "Florida Atlantic",
      value: "FAU"
  },
  {
      label: "Florida International",
      value: "FIU"
  },
  {
      label: "Florida State",
      value: "FSU"
  },
  {
      label: "Fresno State",
      value: "FRES"
  },
  {
      label: "Georgia",
      value: "UGA"
  },
  {
      label: "Georgia Southern",
      value: "GASO"
  },
  {
      label: "Georgia State",
      value: "GAST"
  },
  {
      label: "Georgia Tech",
      value: "GT"
  },
  {
      label: "Hawai'i",
      value: "HAW"
  },
  {
      label: "Houston",
      value: "HOU"
  },
  {
      label: "Illinois",
      value: "ILL"
  },
  {
      label: "Indiana",
      value: "IND"
  },
  {
      label: "Iowa",
      value: "IOWA"
  },
  {
      label: "Iowa State",
      value: "ISU"
  },
  {
      label: "Jacksonville State",
      value: "JVST"
  },
  {
      label: "James Madison",
      value: "JMU"
  },
  {
      label: "Kansas",
      value: "KU"
  },
  {
      label: "Kansas State",
      value: "KSU"
  },
  {
      label: "Kent State",
      value: "KENT"
  },
  {
      label: "Kentucky",
      value: "UK"
  },
  {
      label: "Liberty",
      value: "LIB"
  },
  {
      label: "Louisiana",
      value: "ULL"
  },
  {
      label: "Louisiana Monroe",
      value: "ULM"
  },
  {
      label: "Louisiana Tech",
      value: "LT"
  },
  {
      label: "Louisville",
      value: "LOU"
  },
  {
      label: "LSU",
      value: "LSU"
  },
  {
      label: "Marshall",
      value: "MRSH"
  },
  {
      label: "Maryland",
      value: "MD"
  },
  {
      label: "Memphis",
      value: "MEM"
  },
  {
      label: "Miami",
      value: "MIAMI"
  },
  {
      label: "Miami (OH)",
      value: "M-OH"
  },
  {
      label: "Michigan",
      value: "MICH"
  },
  {
      label: "Michigan State",
      value: "MSU"
  },
  {
      label: "Middle Tennessee",
      value: "MTSU"
  },
  {
      label: "Minnesota",
      value: "MINN"
  },
  {
      label: "Mississippi State",
      value: "MSST"
  },
  {
      label: "Missouri",
      value: "MIZ"
  },
  {
      label: "Navy",
      value: "NAVY"
  },
  {
      label: "NC State",
      value: "NCST"
  },
  {
      label: "Nebraska",
      value: "NEB"
  },
  {
      label: "Nevada",
      value: "NEV"
  },
  {
      label: "New Mexico",
      value: "UNM"
  },
  {
      label: "New Mexico State",
      value: "NMSU"
  },
  {
      label: "North Carolina",
      value: "UNC"
  },
  {
      label: "Northern Illinois",
      value: "NIU"
  },
  {
      label: "North Texas",
      value: "UNT"
  },
  {
      label: "Northwestern",
      value: "NW"
  },
  {
      label: "Notre Dame",
      value: "ND"
  },
  {
      label: "Ohio",
      value: "OHIO"
  },
  {
      label: "Ohio State",
      value: "OSU"
  },
  {
      label: "Oklahoma",
      value: "OKLA"
  },
  {
      label: "Oklahoma State",
      value: "OKST"
  },
  {
      label: "Old Dominion",
      value: "ODU"
  },
  {
      label: "Ole Miss",
      value: "MISS"
  },
  {
      label: "Oregon",
      value: "ORE"
  },
  {
      label: "Oregon State",
      value: "ORST"
  },
  {
      label: "Penn State",
      value: "PSU"
  },
  {
      label: "Pittsburgh",
      value: "PITT"
  },
  {
      label: "Purdue",
      value: "PUR"
  },
  {
      label: "Rice",
      value: "RICE"
  },
  {
      label: "Rutgers",
      value: "RUTG"
  },
  {
      label: "Sam Houston State",
      value: "SHSU"
  },
  {
      label: "San Diego State",
      value: "SDSU"
  },
  {
      label: "San José State",
      value: "SJSU"
  },
  {
      label: "SMU",
      value: "SMU"
  },
  {
      label: "South Alabama",
      value: "USA"
  },
  {
      label: "South Carolina",
      value: "SC"
  },
  {
      label: "Southern Mississippi",
      value: "USM"
  },
  {
      label: "South Florida",
      value: "USF"
  },
  {
      label: "Stanford",
      value: "STAN"
  },
  {
      label: "Syracuse",
      value: "SYR"
  },
  {
      label: "TCU",
      value: "TCU"
  },
  {
      label: "Temple",
      value: "TEM"
  },
  {
      label: "Tennessee",
      value: "TENN"
  },
  {
      label: "Texas",
      value: "TEX"
  },
  {
      label: "Texas A&M",
      value: "TA&M"
  },
  {
      label: "Texas State",
      value: "TXST"
  },
  {
      label: "Texas Tech",
      value: "TTU"
  },
  {
      label: "Toledo",
      value: "TOL"
  },
  {
      label: "Troy",
      value: "TROY"
  },
  {
      label: "Tulane",
      value: "TULN"
  },
  {
      label: "Tulsa",
      value: "TLSA"
  },
  {
      label: "UAB",
      value: "UAB"
  },
  {
      label: "UCF",
      value: "UCF"
  },
  {
      label: "UCLA",
      value: "UCLA"
  },
  {
      label: "UMass",
      value: "UMASS"
  },
  {
      label: "UNLV",
      value: "UNLV"
  },
  {
      label: "USC",
      value: "USC"
  },
  {
      label: "Utah",
      value: "UTAH"
  },
  {
      label: "Utah State",
      value: "USU"
  },
  {
      label: "UTEP",
      value: "UTEP"
  },
  {
      label: "UT San Antonio",
      value: "UTSA"
  },
  {
      label: "Vanderbilt",
      value: "VAN"
  },
  {
      label: "Virginia",
      value: "UVA"
  },
  {
      label: "Virginia Tech",
      value: "VT"
  },
  {
      label: "Wake Forest",
      value: "WAKE"
  },
  {
      label: "Washington",
      value: "WASH"
  },
  {
      label: "Washington State",
      value: "WSU"
  },
  {
      label: "Western Kentucky",
      value: "WKU"
  },
  {
      label: "Western Michigan",
      value: "WMU"
  },
  {
      label: "West Virginia",
      value: "WVU"
  },
  {
      label: "Wisconsin",
      value: "WIS"
  },
  {
      label: "Wyoming",
      value: "WYO"
  }
]


function FilterPopOver  ({setConfFilter, setTeamFilter}) {
  const [pickerItemsConf, setPickerItemsConf] = React.useState(conferences);
  const [pickerItemsTeam, setPickerItemsTeam] = React.useState(teams);

  const [selectedItemsConf, setSelectedItemsConf] = React.useState();
  const [selectedItemsTeam, setSelectedItemsTeam] = React.useState();

  const handleCreateItemConf = (item) => {
    setPickerItemsConf((curr) => [...curr, item]);
    setSelectedItemsConf((curr) => [...curr, item]);
  };

  const handleSelectedItemsChangeConf = (selectedItems) => {
    if (selectedItems) {
      setSelectedItemsConf(selectedItems);
      setConfFilter(selectedItems)
    }
  };

  const handleCreateItemTeam = (item) => {
    setPickerItemsTeam((curr) => [...curr, item]);
    setSelectedItemsTeam((curr) => [...curr, item]);
  };

  const handleSelectedItemsChangeTeam = (selectedItems) => {
    if (selectedItems) {
      setSelectedItemsTeam(selectedItems);
      setTeamFilter(selectedItems)

    }
  };


  return (
    <div>
      <Flex>
        <Popover>
          <PopoverTrigger>
            <Button colorScheme="blackAlpha" size='sm' >Filters</Button>
          </PopoverTrigger>

          <Portal>
            <PopoverContent style={{ width: "100%"}}>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Flex
                  style={{
                    content: "flex",
                    flexDirection: "row",
                    gap: "30px",
                  }}
                >
                  <CUIAutoComplete
                    label="Filtered Conferences"
                    placeholder="Type a Conference"
                    onCreateItem={handleCreateItemConf}
                    items={pickerItemsConf}
                    selectedItems={selectedItemsConf}
                    onSelectedItemsChange={(confChanges) =>
                      handleSelectedItemsChangeConf(confChanges.selectedItems)
                    }
                  />

                  <CUIAutoComplete
                    label="Filtered Teams"
                    placeholder="Type in a Team"
                    onCreateItem={handleCreateItemTeam}
                    items={pickerItemsTeam}
                    selectedItems={selectedItemsTeam}
                    listStyleProps={{
                      overflowY: 'scroll',
                      maxHeight: '300px'
                    }}
                    onSelectedItemsChange={(teamChanges) =>
                      handleSelectedItemsChangeTeam(teamChanges.selectedItems)
                    }
                  />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </div>
  );
};

export default FilterPopOver;
