import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Flex, Button, Textarea, Input } from "@chakra-ui/react";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();

  const [name, setName] = React.useState("");
  const handleName = (event) => setName(event.target.value);

  const [email, SetEmail] = React.useState("");
  const handleEmail = (event) => SetEmail(event.target.value);

  const [body, setBody] = React.useState("");
  const handleBody = (event) => setBody(event.target.value);

  const sendEmail = (e) => {
    setName("");
    SetEmail("");
    setBody("");

    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY,
      )
      .then(
        (result) => {
          alert("message sent successfully...");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
        <Flex flexDirection="column" align="center" justifyContent="center">
          <Input
            size="md"
            minW="50"
            w="500px"
            variant="outline"
            placeholder="Name"
            type="text"
            name="user_name"
            value={name}
            onChange={handleName}
          />

          <Input
            size="md"
            minW="50"
            w="500px"
            mt="10px"
            value={email}
            onChange={handleEmail}
            variant="outline"
            placeholder="Email"
            type="email"
            name="user_email"
          />

          <Textarea
            rows={10}
            height="auto"
            minW="50"
            mt="10px"
            w="500px"
            value={body}
            onChange={handleBody}
            placeholder="How can we help you?"
            type="text"
            name="message"
          />
        </Flex>

        <Flex
          flexDirection="row"
          align="center"
          justifyContent="center"
          mt="10px"
        >
          <Flex w="500px" justifyContent="space-between">
            <Link to="/">
              <Button colorScheme="blackAlpha">Return To Menu</Button>
            </Link>

            <Link to="/">
              <Button
                colorScheme="blackAlpha"
                type="submit"
                onClick={sendEmail}
              >
                Submit
              </Button>
            </Link>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};
export default ContactUs;
