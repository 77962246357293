import React from "react";
import { Link, useLocation } from "react-router-dom";
import redZoneLogo from "../../asset/images/logoTransparent.png"


import {
  Box,
  Menu,
  MenuButton,
  Button,
  Flex,
  Spacer,
  Heading,
  Image,
} from "@chakra-ui/react";


const MenuBar = () => {
  const url = useLocation()

  let routeVal = ''
  let buttonVal = ''
  if(url.pathname === '/'){
    routeVal='/Email'
    buttonVal = 'Email'
  }else{
    routeVal = '/'
    buttonVal = 'Videos'
  }


  return (
    <Flex minWidth="max-content" alignItems="center" gap="2" mr="5" marginTop="-20px">
      <Menu>
        <Box>
          <Heading size="md" display="flex" flexDirection="row" alignItems="center">
            <Image src={redZoneLogo} maxHeight="150px"></Image>
              Redzone Review
            </Heading>
        </Box>
        <Link to={routeVal}>
          <Button as={Button} marginLeft="20px" colorScheme="blackAlpha" to={routeVal}>
            {buttonVal}
          </Button>
        </Link>
        <Spacer />
        <Link to="/ContactUs">
          <Button as={Button} colorScheme="blackAlpha">
            Contact Us
          </Button>
        </Link>
      </Menu>
    </Flex>
  );
};

export default MenuBar;
