import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import ContactUs from "./views/ContactUs";
import OptOut from "./views/OptOut";
import Video from "./views/Video";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Video />} />
        <Route exact path="/ContactUs" element={<ContactUs />} />
        <Route exact path="/OptOut" element={<OptOut />} />
        <Route exact path="/Email" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
