import React from "react";
import { Flex, Button, Text, Input } from "@chakra-ui/react";

//main
// m="5" ml="40" mr="40" w="100%"
const Home = () => {
  const apiURL = "https://x792dhpuv1.execute-api.us-east-1.amazonaws.com/dev";
  const [email, SetEmail] = React.useState("");
  const [sent, setSent] = React.useState(false);

  const handleChange = (event) => SetEmail(event.target.value);

  const submitAddress = async () => {
    if (sent === false) {
      fetch(apiURL + "/EmailSubmission", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();}
            {
            throw new Error("Post Failed");
          }
        })
        .then(function (responseBody) {
          console.log(responseBody);
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      setSent(true);
    }
  };

  return (
    <Flex display="flex" minWidth="100%" justifyContent="center">

      <Flex display="flex" alignContent="flex-start" flexDirection="column">
        <Text textAlign="center" as="b">College Football Highlights Right To You</Text>
        <Text noOfLines={1} m="5" />
        <Text>
          Miss the old days of being able to watch college football highlights,
          without having someone scream at you about an opinon?
        </Text>
        <Text noOfLines={1} m="5" />
        <Text paddingBottom="20px">
          Subscribe below for the best college football highlight videos curated
          for you. Free, no spam, unsubscribe whenever you want
        </Text>

        <Flex align="center" justifyContent="center">
          <Input
            size="md"
            minW="50%"
            w="300px"
            variant="outline"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          <Button size="md" colorScheme="blackAlpha" ml="5px" onClick={() => submitAddress()}>
            Subscribe
          </Button>
        </Flex>
      </Flex>

    </Flex>
  );
};
export default Home;
