import React, { useState, useEffect } from "react";
import FilterPopOver from "../components/FilterPopOver";
import { Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import ReactPlayer from 'react-player/lazy'


const Home = () => {
  const [videos, setVideos] = useState();
  const [filterVid, setFilterVideos] = useState();
  let [shownVideo, setShownVideo] = useState(10);
  let [teamFilter, setTeamFilter] = useState();
  let [confFilter, setConfFilter] = useState();
  let [teamFilterCount, setTeamFilterCount] = useState(0);
  let [confFilterCount, setConfFilterCount] = useState(0);

  const apiURL = "https://x792dhpuv1.execute-api.us-east-1.amazonaws.com/dev";

  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = async () => {
    axios
      .get(apiURL + "/Videos", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setVideos(response.data.body.results);
        setFilterVideos(response.data.body.results)
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom && shownVideo) {
      setShownVideo((shownVideo += 4));
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
  }, []);

  //Filter area
  React.useEffect(() => {
    let teamResult = []
    let confResult = []
      if (teamFilter && teamFilter.length > 0){
       let nameArr = teamFilter.map((item) => item.label)
      teamResult = videos.filter(video => {
        // Check if video.team_one or video.team_two is in nameArr
        return nameArr.includes(video.team_one) || nameArr.includes(video.team_two);
      });
      }
      if (confFilter && confFilter.length > 0){
        let nameArr = confFilter.map((item) => item.label)
        confResult = videos.filter(video => {
         // Check if video.team_one or video.team_two is in nameArr
         return nameArr.includes(video.home_conferences) || nameArr.includes(video.away_conference);
       });
       }
        let tempFilter = [...teamResult, ...confResult]
        setFilterVideos(tempFilter)
        if(confFilter){
          setConfFilterCount(confFilter.length)
        }
        if(teamFilter){
          setTeamFilterCount(teamFilter.length)
        }
       
  }, [teamFilter, confFilter]);


  React.useEffect(() => {
    if(teamFilterCount === 0 && confFilterCount === 0){
      setFilterVideos(videos)
     }
  }, [teamFilterCount, confFilterCount])



  return (
    <Flex
      style={{
        content: "flex",
        flexDirection: "column",
      }}
    >
      <Flex
        style={{
          paddingLeft: "20px",
        }}
      >
        <FilterPopOver
          setConfFilter={setConfFilter}
          setTeamFilter={setTeamFilter}
         />
      
      </Flex>

      <Flex
        style={{
          content: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "100%",
          justifyContent: "center",
        }}
      >
        <div style={{ content: "flex", alignContent: "flex-start" }}></div>

        {filterVid &&
          filterVid.slice(0, shownVideo).map((filterVid)=> (
            <div key={filterVid.id} style={{ padding: "20px" }}>
              <Text textAlign="center">
                {filterVid.team_one} VS {filterVid.team_two}
              </Text>

              <div
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  zIndex: "1",
                  height: "315",
                  width: "560",
                }}
              >
                <ReactPlayer url={`https://www.youtube.com/embed/${filterVid.yt_video_id}`}
                light={true}
                controls={true}
                playing />

              </div>
            </div>
          ))}
      </Flex>
    </Flex>
  );
};
export default Home;
